import { render, staticRenderFns } from "./Url.vue?vue&type=template&id=74684ede&scoped=true"
import script from "./Url.vue?vue&type=script&lang=js"
export * from "./Url.vue?vue&type=script&lang=js"
import style0 from "./Url.vue?vue&type=style&index=0&id=74684ede&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74684ede",
  null
  
)

export default component.exports