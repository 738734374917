module.exports = {
    catalogUrl: "",
    catalogTitle: "IO STAC Browser",
    allowExternalAccess: true, // Must be true if catalogUrl is not given
    allowedDomains: [
        'api.impactobservatory.com'
    ],
    detectLocaleFromBrowser: true,
    storeLocale: true,
    locale: "en",
    fallbackLocale: "en",
    supportedLocales: [
        "de",
//      "de-CH",
        "es",
        "en",
        "fr",
//      "fr-CA",
//      "fr-CH",
        "it",
//      "it-CH",
        "ro"
    ],
    apiCatalogPriority: null,
    tileSourceTemplate: null,
    displayGeoTiffByDefault: false,
    useTileLayerAsFallback: false, // we want to use server side rendering
    buildTileUrlTemplate: ({href, asset}) => 'https://api.impactobservatory.com/titiler-internal/cog/tiles/{z}/{x}/{y}@1x?url=' + encodeURIComponent(asset.href.startsWith('/vsi') ? asset.href : href),
    stacProxyUrl: null,
    pathPrefix: "/",
    historyMode: "history",
    cardViewMode: "cards",
    cardViewSort: "asc",
    showThumbnailsAsAssets: false,
    stacLint: true,
    geoTiffResolution: 128,
    redirectLegacyUrls: false,
    itemsPerPage: 12,
    defaultThumbnailSize: null,
    maxPreviewsOnMap: 50,
    crossOriginMedia: null,
    authConfig: {
        type: 'query', // null or 'query' or 'header'
        key: 'apikey',
        description: `API subscription key`
    },
    requestQueryParameters: {},
    preprocessSTAC: (stac, state) => {
        if (stac.type === "Catalog") {
            // rename less than helpful catalog titles
            console.log(stac.getBrowserPath(), state)
            if (stac.getBrowserPath() === '/external/api.impactobservatory.com/stac-internal-browser/') {
                stac.title = "IO STAC Internal Browser";
                stac.description = 'Impact Observatory STAC for Internal Use with STAC Browser';
            } else if (stac.getBrowserPath() === '/external/api.impactobservatory.com/stac-navigator/') {
                stac.title = "IO STAC Navigator";
                stac.description = 'Impact Observatory STAC for Navigator';
            }
        }
        return stac;
    }
};
