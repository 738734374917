import { render, staticRenderFns } from "./ErrorAlert.vue?vue&type=template&id=09bb7d1b&scoped=true"
import script from "./ErrorAlert.vue?vue&type=script&lang=js"
export * from "./ErrorAlert.vue?vue&type=script&lang=js"
import style0 from "./ErrorAlert.vue?vue&type=style&index=0&id=09bb7d1b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09bb7d1b",
  null
  
)

export default component.exports